import { Calendar } from '@fullcalendar/core'

import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import iCalendarPlugin from '@fullcalendar/icalendar'
import deLocale from '@fullcalendar/core/locales/de'
import multiMonthPlugin from '@fullcalendar/multimonth'

document.addEventListener('turbo:load', () => {
  document.querySelectorAll('.fullcalendar').forEach(element => {
    const calendar = new Calendar(element, {
      themeSystem: 'bootstrap',
      initialView: 'timeGridWeek',
      slotDuration: '00:30:00',
      slotMinTime: '06:00:00',
      slotMaxTime: '24:00:00',
      weekNumbers: true,
      navLinks: true,
      weekText: 'KW',
      weekends: false,
      allDaySlot: false,
      nowIndicator: true,
      firstDay: 1,
      locale: deLocale,
      slotEventOverlap: true,
      events: {
        url: element.dataset.icsUrl,
        format: 'ics'
      },
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'listWeek,dayGridMonth,timeGridWeek,multiMonthYear'
      },
      businessHours: {
        daysOfWeek: [1, 2, 3, 4, 5],
        startTime: '07:00',
        endTime: '17:00'
      },
      slotLabelFormat: {
        hour: '2-digit',
        minute: '2-digit',
        meridiem: 'short',
        omitZeroMinute: false
      },
      eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        meridiem: 'short',
        omitZeroMinute: false
      },
      plugins: [
        dayGridPlugin,
        timeGridPlugin,
        listPlugin,
        iCalendarPlugin,
        multiMonthPlugin
      ],
      eventClick: function (info) {
        console.log(info)
      }
    })

    calendar.render()
  })
})
