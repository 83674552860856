import TomSelect from 'tom-select'

const selector = '.tom-select:not(.vanilla), select:not(.vanilla)'

const setup = (root) => {
  root.querySelectorAll(selector).forEach(element => {
    if (element.closest('.flatpickr-months')) return

    if (element.tomselect) {
      element.tomselect.destroy()
    }

    const options = {
      create: element.dataset.tsCreate === 'true',
      allowEmptyOption: element.dataset.tsAllowEmptyOption === 'true',
      plugins: {
        caret_position: {},
        clear_button: {
          title: 'Remove all selected options'
        }
      }
    }

    if (element.getAttribute('multiple' !== null)) {
      options.plugins.remove_button = {}
    }

    const ts = new TomSelect(element, options)

    ts.on('change', (event) => {
      if (ts.currentResults?.query) {
        ts.close()
        ts.open()
        // ts.refreshItems() => causes stack overflow
      }
    })
  })
}

document.addEventListener('turbo:load', () => {
  setup(document)
})

document.addEventListener('stimulus-reflex:success', (event) => {
  const root = event.srcElement.dataset.reflexRoot
  if (root) {
    document.querySelectorAll(root).forEach(element => {
      setup(element)
    })
  }
})
