import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = []

  addEmployeeSuccess () {
    this.cleanUp()
  }

  removeEmployeeSuccess () {
    this.cleanUp()
  }

  cleanUp () {
    document.body.classList.remove('modal-open')
    document.querySelectorAll('.modal-backdrop').forEach(e => e.remove())
  }
}
