import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  click (event) {
    event.preventDefault()

    this.frame.src = this.href(event.target)

    document.querySelectorAll('.client[data-active],.client-name[data-active],.client-address[data-active]').forEach(element => {
      element.removeAttribute('data-active')
    })

    const enclosingElement = event.target.closest('.client')

    enclosingElement.setAttribute('data-active', 'true')
    enclosingElement.querySelector('.client-name').setAttribute('data-active', 'true')
    enclosingElement.querySelector('.client-address').setAttribute('data-active', 'true')
  }

  get frame () {
    return document.querySelector('turbo-frame#clients')
  }

  href (element) {
    if (element.dataset.href) {
      return element.dataset.href
    } else {
      if (element.localName !== 'body') {
        return this.href(element.parentElement)
      }
    }
  }
}
