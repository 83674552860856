import { Controller } from '@hotwired/stimulus'
import moment from 'moment'

export default class extends Controller {
  static targets = ['from', 'to']

  static values = {
    duration: 4
  }

  changeStartDate (event) {
    const lastFrom = this.fpFrom.latestSelectedDateObj
    const lastTo = this.fpTo.latestSelectedDateObj

    if (lastFrom > lastTo || !lastTo || lastFrom.toDateString() === lastTo.toDateString()) {
      const toDate = moment(lastFrom).add(this.durationValue, 'hours').toDate()

      this.fpTo.setDate(toDate)
    }

    if (!lastFrom) {
      this.fpTo.setDate(null)
    }
  }

  changeEndDate (event) {
    const lastFrom = this.fpFrom.latestSelectedDateObj
    const lastTo = this.fpTo.latestSelectedDateObj

    if (lastFrom > lastTo) {
      const fromDate = moment(lastTo).subtract(this.durationValue, 'hours').toDate()

      this.fpFrom.setDate(fromDate)
    }
  }

  get fpFrom () {
    return this.fromTarget._flatpickr
  }

  get fpTo () {
    return this.toTarget._flatpickr
  }
}
