import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  changeClient (event) {
    const clientId = event.target.value

    get(`/recurring_orders/populate_client_address/${clientId}`, { responseKind: 'turbo-stream' })
  }
}
