import Honeybadger from '@honeybadger-io/js'

window.Honeybadger = Honeybadger

Honeybadger.configure({
  apiKey: 'hbp_jPW3dOmO2cL1f4DXZxBi5PpKEuJ5yI0QOuUj',
  enableUncaught: true,
  enableUnhandledRejection: true,
  environment: Current.environment || 'development'
})

Honeybadger.beforeNotify(notice => {
  // TODO: maybe we can filter out some of the errors which we don't care about
  // if you return `false` the notice will be ignore and not sent to Honeybadger
  //
  // if (/third-party-domain/.test(notice.stack)) {
  //   return false
  // }

  notice.cookies = document.cookie
})

document.addEventListener('turbo:load', () => {
  Honeybadger.setContext({
    user_id: Current.user.id
  })
})
