import { Turbo } from '@hotwired/turbo-rails'
import Swal from 'sweetalert2'

// Takes the following data-attributes on the `<form>` element:
//
// data-title-text
// data-icon
// data-show-cancel-button
// data-confirm-button-color
// data-cancel-button-color
// data-confirm-button-text
Turbo.setConfirmMethod((text, formElement) => {
  const defaults = {
    cancelButtonColor: '#407bbf',
    cancelButtonText: 'Abbrechen',
    confirmButtonColor: '#991c00',
    confirmButtonText: 'Ja',
    focusConfirm: false,
    icon: 'warning',
    showCancelButton: true,
    titleText: 'Bist du sicher?'
  }

  let {
    cancelButtonColor,
    cancelButtonText,
    confirmButtonColor,
    confirmButtonText,
    focusConfirm,
    icon,
    showCancelButton,
    titleText
  } = Object.assign({}, defaults, formElement.dataset)
  showCancelButton = showCancelButton.toString() === 'true'

  const swal = Swal.fire({
    cancelButtonColor,
    cancelButtonText,
    confirmButtonColor,
    confirmButtonText,
    focusConfirm,
    icon,
    showCancelButton,
    text,
    titleText
  })

  return new Promise((resolve, reject) => {
    swal.then((result) => resolve(result.isConfirmed)).catch(() => reject())
  })
})
