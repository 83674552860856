import { Controller } from '@hotwired/stimulus'
import moment from 'moment'

export default class extends Controller {
  static targets = ['from', 'to']

  connect () {
    if (this.hasFromTarget) {
      this.fromTarget.setAttribute('data-action', 'change->holiday#changeStartDate')
    }

    if (this.hasToTarget) {
      this.toTarget.setAttribute('data-action', 'change->holiday#changeEndDate')
    }
  }

  changeStartDate (event) {
    if (this.fpFrom.latestSelectedDateObj) {
      const fromDate = moment(this.fpFrom.latestSelectedDateObj)
      let toDate = moment(this.fpTo.latestSelectedDateObj)
      toDate = toDate.date(fromDate.date()).month(fromDate.month()).year(fromDate.year())

      this.fpTo.setDate(toDate.toDate())
    } else {
      this.fpTo.setDate(null)
    }
  }

  changeEndDate (event) {
    if (this.fpTo.latestSelectedDateObj) {
      const fromDate = moment(this.fpTo.latestSelectedDateObj)
      let toDate = moment(this.fpFrom.latestSelectedDateObj)
      toDate = toDate.date(fromDate.date()).month(fromDate.month()).year(fromDate.year())

      this.fpFrom.setDate(toDate.toDate())
    } else {
      this.fpFrom.setDate(null)
    }
  }

  get fpFrom () {
    return this.fromTarget._flatpickr
  }

  get fpTo () {
    return this.toTarget._flatpickr
  }
}
