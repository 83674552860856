import hotkeys from 'hotkeys-js'

document.addEventListener('turbo:load', function () {
  hotkeys('f', function (event, handler) {
    event.preventDefault()
    $("a[href='#sidebarModalSearch']").trigger('click')
    $('.mb-4 .input-group-merge .search:first').focus()
  })

  hotkeys('n', function (event, handler) {
    event.preventDefault()
    alert('What do you want to create')
  })
})
