import { StreamActions } from '@hotwired/turbo'
import Toastify from 'toastify-js'

StreamActions.toast = function () {
  const text = this.getAttribute('text')
  const duration = Number(this.getAttribute('duration'))
  const gravity = this.getAttribute('gravity')
  const position = this.getAttribute('position')
  const close = this.getAttribute('close') === 'true'

  const toast = Toastify({
    text,
    duration,
    gravity,
    position,
    close
  })

  toast.showToast()
}
