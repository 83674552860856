import { Tooltip, Collapse, Dropdown } from 'bootstrap'

window.initBootstrap = () => {
  document.querySelectorAll('[data-toggle="tooltip"]').forEach(element => {
    if (element.title) {
      element.setAttribute('data-bs-title', element.title)
      element.removeAttribute('data-title')
    }

    if (element.dataset.placement) {
      element.setAttribute('data-bs-placement', element.dataset.placement)
      element.removeAttribute('data-placement')
    }

    element.setAttribute('data-bs-toggle', 'tooltip')
    element.removeAttribute('data-toggle')
  })

  document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(element => {
    element.bootstrapTooltip = new Tooltip(element)
  })

  document.querySelectorAll('[data-toggle="collapse"]').forEach(element => {
    if (element.dataset.target) {
      element.setAttribute('data-bs-target', element.dataset.target)
      element.removeAttribute('data-target')
    }

    element.setAttribute('data-bs-toggle', 'collapse')
    element.removeAttribute('data-toggle')
  })

  document.querySelectorAll('[data-bs-toggle="collapse"]').forEach(element => {
    element.bootstrapCollapse = new Collapse(element, { toggle: false })
  })

  document.querySelectorAll('[data-toggle="dropdown"]').forEach(element => {
    element.setAttribute('data-bs-toggle', 'dropdown')
    element.removeAttribute('data-toggle')
  })

  document.querySelectorAll('[data-bs-toggle="dropdown"]').forEach(element => {
    element.bootstrapDropdown = new Dropdown(element)
  })
}

document.addEventListener('turbo:load', (_event) => {
  window.initBootstrap()
})

document.addEventListener('turbo:render', (_event) => {
  window.initBootstrap()
})

document.addEventListener('turbo:before-cache', (event) => {
  event.target.querySelectorAll('.tooltip.bs-tooltip-auto[role="tooltip"]').forEach(element => {
    element.remove()
  })
})
