import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'name',
    'employees',
    'groupLeader'
  ]

  changed (event) {
    const groupLeader = this.groupLeaderTarget
    const newOptions = Array.from(event.target.selectedOptions)
    const newValues = newOptions.map(option => option.value)

    if (!newValues.includes(groupLeader.tomselect.getValue())) {
      groupLeader.tomselect.clear()
      groupLeader.tomselect.clearActiveOption()
    }

    groupLeader.tomselect.clearOptions()
    groupLeader.tomselect.addOptions(newOptions)

    if (groupLeader.tomselect.activeItems.length === 0) {
      groupLeader.tomselect.setValue(newOptions[0].value)
    }

    groupLeader.tomselect.refreshItems()

    this.updateGroupName()
  }

  updateGroupName () {
    this.nameTarget.value = `Gruppe ${this.groupLeaderTarget.selectedOptions[0].textContent.split(' ')[0]}`
  }

  add (event) {
    console.log(this.employeesTarget.content.children)
  }
}
