import List from 'list.js'

document.addEventListener('turbo:load', () => {
  const lists = document.querySelectorAll('[data-toggle="lists"]')
  const sorts = document.querySelectorAll('[data-toggle="lists"] [data-sort]')

  lists.forEach(list => {
    const options = list.dataset.options ? JSON.parse(list.dataset.options) : {}

    try {
      list.listjs = new List(list, options)
    } catch {}
  })

  sorts.forEach(sort => sort.addEventListener('click', e => e.preventDefault()))
})
