import { Controller } from '@hotwired/stimulus'
import moment from 'moment'

export default class extends Controller {
  static targets = ['reason', 'from', 'to']

  connect () {
    if (this.hasFromTarget) {
      this.fromTarget.setAttribute('data-action', 'change->absences#changeStartDate')
    }
  }

  changeType (event) {
    if (this.hasReasonTarget) {
      this.reasonTarget.value = event.target.selectedOptions[0].textContent
    }
  }

  changeStartDate (event) {
    if (this.fpFrom.latestSelectedDateObj > this.fpTo.latestSelectedDateObj || !this.fpTo.latestSelectedDateObj) {
      const toDate = moment(this.fpFrom.latestSelectedDateObj).add(9, 'hours').toDate()

      this.fpTo.setDate(toDate)
    }

    if (!this.fpFrom.latestSelectedDateObj) {
      this.fpTo.setDate(null)
    }
  }

  get fpFrom () {
    return this.fromTarget._flatpickr
  }

  get fpTo () {
    return this.toTarget._flatpickr
  }
}
