import ApplicationController from './application_controller'
import StimulusReflex from 'stimulus_reflex'
import moment from 'moment'

export default class extends ApplicationController {
  static values = {
    duration: Number,
    appointments: Number
  }

  static targets = [
    'startDate',
    'endDate',
    'fromWeek',
    'toWeek',
    'group',
    'week',
    'interval',
    'intervalType',
    'block',
    'execution',
    'spinner',
    'selectorType'
  ]

  connect () {
    StimulusReflex.register(this)
    this.selectWeeks()
    this.selectedDayCache = []
  }

  selectWeeks (event) {
    const fromWeek = new Date(this.fromWeekTarget.value)
    const fromWeekNumber = moment(fromWeek).week() - 1
    const toWeek = new Date(this.toWeekTarget.value)

    const interval = Number(this.intervalTarget.value)
    const intervalTypeValue = Number(this.intervalTypeTarget.value)

    this.weekTargets.forEach((week, weekIndex) => {
      const weekDate = new Date(week.dataset.date)
      const index = this.weekTargets.indexOf(week)

      if (fromWeek <= weekDate && weekDate <= toWeek && index % (interval * intervalTypeValue) === fromWeekNumber % (interval * intervalTypeValue)) {
        week.checked = true
      } else {
        week.checked = false
      }
    })

    if (event) {
      this.previewSrcElement = event.srcElement
      this.previewParams = this.getParams()

      this.saveData(event)
      // this.preview(event);
    }
  }

  toggleCheckbox (element) {
    if (element.dataset.checked === 'true') {
      element.dataset.checked = 'false'
    } else {
      element.dataset.checked = 'true'
    }

    element.classList.toggle('checked')
  }

  unselectAll (blocks) {
    blocks.filter(element => element.dataset.checked === 'true').forEach(element => this.toggleCheckbox(element))
  }

  switchType (event) {
    const value = event.target.value

    if (value === 'x') {
      const single = this.element.querySelectorAll('[data-action="click->workload#singleBlockUpdate"]')

      single.forEach(element => {
        element.setAttribute('data-action', 'click->workload#blockUpdate')
      })
    } else {
      const multiple = this.element.querySelectorAll('[data-action="click->workload#blockUpdate"]')

      multiple.forEach(element => {
        element.setAttribute('data-action', 'click->workload#singleBlockUpdate')
      })
    }
  }

  singleBlockUpdate (event) {
    this.toggleCheckbox(event.srcElement)

    this.saveData(event)
  }

  blockUpdate (event) {
    if (!this.selectedDayCache.includes(event.srcElement.dataset.day)) {
      this.selectedDayCache.push(event.srcElement.dataset.day)

      if (this.selectedDayCache.length > this.appointmentsValue) {
        const shifted = this.selectedDayCache.shift()
        const shiftedBlocks = this.blockTargets.filter(element => element.dataset.day === shifted)
        this.unselectAll(shiftedBlocks)
      }
    }

    const sameDayBlocks = this.blockTargets.filter(element => element.dataset.day === event.srcElement.dataset.day)
    const indexSource = sameDayBlocks.indexOf(event.srcElement)
    const selectedBlocks = sameDayBlocks.slice(indexSource, indexSource + this.durationValue)

    const allSelectedPreviously = Array.from(new Set(selectedBlocks.map(block => block.dataset.checked === 'true')))

    this.unselectAll(sameDayBlocks)

    if (!(allSelectedPreviously.length === 1 && allSelectedPreviously[0] === true)) {
      selectedBlocks.forEach(block => this.toggleCheckbox(block))
    }

    this.saveData(event)
  }

  saveData (event) {
    this.previewSrcElement = event.srcElement
    this.previewParams = this.getParams()

    document.getElementById('appointments_preview').classList.add('tw-blur-sm')
    document.getElementById('submit-button').classList.add('tw-hidden')

    this.spinnerTarget.querySelector('.new').classList.remove('tw-hidden')

    this.spinnerTarget.classList.remove('tw-hidden')
    this.spinnerTarget.classList.remove('btn-primary')
    this.spinnerTarget.classList.add('btn-danger')
    this.spinnerTarget.disabled = false
  }

  async preview (event) {
    event.preventDefault()

    if (this.previewSrcElement && this.previewParams) {
      this.spinnerTarget.classList.add('btn-primary')
      this.spinnerTarget.classList.remove('btn-danger')

      this.spinnerTarget.disabled = true

      this.spinnerTarget.querySelector('.new').classList.add('tw-hidden')
      this.spinnerTarget.querySelector('.spinner').classList.remove('tw-hidden')

      await this.stimulate('WorkloadReflex#preview', this.previewSrcElement, this.previewParams)

      this.spinnerTarget.querySelector('.spinner').classList.add('tw-hidden')
    }
  }

  // changeStartDate(event) {
  //   const date = this.dateHelper(this.startDateTargets);
  //   this.stimulate("WorkloadReflex#change_start_date", event.srcElement, date);
  // }

  // changeEndDate(event) {
  //   const date = this.dateHelper(this.endDateTargets);
  //   this.stimulate("WorkloadReflex#change_end_date", event.srcElement, date);
  // }

  dateHelper (elements) {
    const dateParts = []

    elements.forEach((select) => {
      dateParts.push(select.value.padStart(2, '0'))
    })

    return dateParts.join('-')
  }

  getParams () {
    const weeks = this.weekTargets.map(week => {
      if (week.checked) {
        return week.value
      }
    }).filter(week => week)

    const blocks = this.blockTargets.map((block) => {
      if (block.dataset.checked === 'true') {
        return JSON.parse(block.dataset.key)
      }
    }).filter(block => block)

    const blockIds = this.blockTargets.map((block) => {
      if (block.dataset.checked === 'true') {
        return `input-${block.id}`
      }
    }).filter(block => block)

    const inputBlocks = Array.from(document.querySelectorAll('.input-blocks'))

    const checkedBlockInputs = inputBlocks.map(input => {
      if (blockIds.includes(input.id)) {
        input.checked = true
        return input
      } else {
        input.checked = false
        return null
      }
    }).filter(input => input)

    // console.log(checkedBlockInputs)

    return {
      // start_date: this.dateHelper(this.startDateTargets),
      // end_date: this.dateHelper(this.endDateTargets),
      // group_id: this.groupTarget.value,
      recurring_order_execution_id: this.executionTarget.value,
      weeks,
      blocks
    }
  }
}
