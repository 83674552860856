import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['outlineType']

  connect () {
    this.hideAllTargets()

    if (!this.checkedInput) {
      this.inputElements[0].checked = true
      this.changeType()
    }
  }

  changeType () {
    this.hideAllTargets()
    this.clearAllInputs()
    this.unselectAllLabels()

    const value = this.checkedInput.value
    const element = this.targetElements.find(element => element.dataset.outlineType === value)

    this.checkedInput.closest('label').classList.add('tw-border-indigo-500', 'tw-ring-2', 'tw-ring-indigo-500', 'tw-bg-indigo-50')
    element.classList.remove('tw-hidden')
  }

  clearAllInputs () {
    // this.targetElements.forEach(element => {
    //   element.querySelectorAll("input").forEach(input => input.value = null)
    //   element.querySelectorAll("select").forEach(input => input.tomselect?.clear())
    // })
  }

  hideAllTargets () {
    this.targetElements.forEach(element => element.classList.add('tw-hidden'))
  }

  unselectAllLabels () {
    this.inputElements.forEach(element => element.closest('label').classList.remove('tw-border-indigo-500', 'tw-ring-2', 'tw-ring-indigo-500', 'tw-bg-indigo-50'))
  }

  get checkedInput () {
    return this.inputElements.find(element => element.checked)
  }

  get inputElements () {
    return Array.from(this.outlineTypeTargets)
  }

  get targetElements () {
    return Array.from(document.querySelectorAll('[data-outline-type]'))
  }
}
