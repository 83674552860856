import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['interval']

  connect () {
    this.setWeeksBasedOnSelection()
  }

  setWeeksBasedOnSelection () {
    document.querySelectorAll('.data-week-selection').forEach(weekElement => {
      this.setWeek(
        weekElement,
        Array.from(document.querySelectorAll(`[data-abstract="false"][data-week="${weekElement.dataset.week}"]`)).some(element => element.checked)
      )
    })
  }

  changeInterval (_event) {
    this.applyWeeks()
    this.applyAbstractCheckboxes()
  }

  toggleWeek (element) {
    const selected = (element.dataset.selected === 'true')
    element.dataset.selected = !selected

    return !selected
  }

  setWeek (element, value) {
    element.dataset.selected = value
  }

  selectWeek (event) {
    const element = event.target
    const selected = (event.target.dataset.selected === 'true')

    this.toggleWeek(element)

    document.querySelectorAll('[data-abstract="true"]').forEach(abstractElement => {
      document.querySelectorAll(`[data-part="${abstractElement.dataset.part}"][data-dow="${abstractElement.dataset.dow}"][data-week="${element.dataset.week}"][data-abstract="false"]`).forEach(e => {
        e.checked = !selected && abstractElement.checked
      })
    })
  }

  applyWeeks () {
    document.querySelectorAll('.data-week-selection').forEach(element => {
      this.setWeek(element, Number(element.dataset.week) % this.interval === 0)
    })
  }

  selectDayPart (event) {
    this.applyForAbstractCheckbox(event.target)
  }

  applyAbstractCheckboxes () {
    document.querySelectorAll('[data-abstract="true"]').forEach(element => {
      this.applyForAbstractCheckbox(element)
    })
  }

  applyForAbstractCheckbox (element) {
    // const interval = this.interval

    if (document.querySelectorAll('.data-week-selection[data-selected="true"]').length === 0) {
      this.applyWeeks()
    }

    document.querySelectorAll(`[data-part="${element.dataset.part}"][data-dow="${element.dataset.dow}"][data-abstract="false"]`).forEach(e => {
      if (document.querySelector(`.data-week-selection[data-week="${e.dataset.week}"]`)?.dataset?.selected === 'true') {
        e.checked = element.checked
      } else {
        e.checked = false
      }
    })
  }

  get interval () {
    if (this.hasIntervalTarget) {
      const value = this.intervalTarget.value

      return Number(value.replace('P', '').replace('D', '')) / 7
    }

    return 1
  }
}
